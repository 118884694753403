import styled from "styled-components"

export const CategoryBox = styled.div`
  max-height: 34px;
  background: ${({ theme }) => theme.gradient.defaultBlueGradient};
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.font.size.xxs};
  font-weight: ${({ theme }) => theme.font.weight.light};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
  padding: 3px 10px;
  width: fit-content;

  ${({ theme }) => theme.mq.md} {
    border-radius: 10px;
    padding: 5px 16px;
  }

  ${({ theme }) => theme.mq.lg} {
    padding: 7px 20px;
  }
`
