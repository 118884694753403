import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Moment from "react-moment"

import DummyImage from "../../../content/assets/dummyImage.png"
import DummyIcon from "../../../content/assets/dummyIcon.png"
import { CategoryBox } from "../CategoryBox"
const slugify = require("slugify")

const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6px;
  align-items: center;
  max-width: 360px;
  max-height: 400px;
  transition: all 0.3s linear;
  border-radius: 10px;
  height: 100%;

  &:hover {
    box-shadow: 3px 3px 40px #00000029;
  }

  ${({ theme }) => theme.mq.md} {
    padding: 14px;
    border-radius: 15px;
  }

  ${({ theme }) => theme.mq.lg} {
    padding: 20px;
  }
`

const ImageBox = styled.div`
  max-width: 374px;
  max-height: 200px;
  position: relative;
  margin-bottom: 7px;

  img {
    width: 320px;
    height: 200px;
    border-radius: 15px;
    object-fit: cover;
    max-width: 100%;
  }
`

export const Category = styled(CategoryBox)`
  position: absolute;
  left: 15px;
  top: 10px;

  ${({ theme }) => theme.mq.md} {
    left: 25px;
    top: 15px;
  }

  ${({ theme }) => theme.mq.lg} {
    left: 30px;
    top: 20px;
  }
`

export const PostTitle = styled.p`
  font-size: ${({ theme }) => theme.font.size.xs};
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  text-align: left;
  margin-bottom: 0.5rem;
  max-width: 374px;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
  flex-grow: 1;

  ${({ theme }) => theme.mq.md} {
    font-size: ${({ theme }) => theme.font.size.sm};
  }
`

export const PostDescription = styled.p`
  color: ${({ theme }) => theme.colors.grayishCyan};
  max-width: 374px;
  width: 100%;
  text-align: left;
  font-size: ${({ theme }) => theme.font.size.xxs};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 1rem;
  overflow: hidden;

  ${({ theme }) => theme.mq.md} {
    font-size: ${({ theme }) => theme.font.size.xxs};
  }
`

export const PostInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.colors.grayishCyan};
  width: 100%;
  align-self: flex-end;
  margin-top: auto;

  font-size: ${({ theme }) => theme.font.size.xxs};

  ${({ theme }) => theme.mq.md} {
    font-size: 16px;
  }
`

export const PostAuthor = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const AuthorIcon = styled.div`
  max-height: 35px;
  max-width: 35px;

  img {
    width: 100%;
    height: auto;
  }
`

export const AuthorName = styled.p`
  display: flex;
  margin-left: 10px;
`

export const PostDate = styled.p``

const GalleryItem = ({ post }) => {
  return (
    <Link to={`/${post.title && slugify(post.title, { lower: true })}`}>
      <Card>
        <ImageBox>
          <img
            src={
              post.zdjecie_glowne?.url ? post.zdjecie_glowne.url : DummyImage
            }
            alt="dummy"
          />
          <Category>{post.kategoria ? post.kategoria : "Kategoria"}</Category>
        </ImageBox>

        <PostTitle>{post.title ? post.title : "Tytuł"}</PostTitle>

        <PostDescription>{post.opis1 ? post.opis1 : "Opis"}</PostDescription>

        <PostInfo>
          <PostAuthor>
            <AuthorIcon>
              <img src={DummyIcon} alt="Author Icon" />
            </AuthorIcon>
            <AuthorName>Grzegorz</AuthorName>
          </PostAuthor>
          <PostDate>
            <Moment format="DD MMM YYYY">{post.data && post.data}</Moment>
          </PostDate>
        </PostInfo>
      </Card>
    </Link>
  )
}

export default GalleryItem
